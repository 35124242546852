.chart-container {
    background-color: #f7f7f7;
    padding: 10px;
    justify-content: center;
    /* font-family: "heebo, sans-serif" !important; */
    margin-bottom: 40px;
}

.blank-chart-container {
    background: #ffffff;
    margin: 10px;
    border-radius: 10px;
    height: 444px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.chart-container>.single-chart {
    border-radius: 10px;
}

.custom-pie-chart {
    background: #ffffff;
    margin: 10px;
    border-radius: 10px;
}

.custom-bar-chart {
    background: #ffffff;
    margin: 10px;
    border-radius: 10px;
    height: 444px;
}

.chart-title {
    color: #000000;
    padding: 30px;
}

.bar-chart-title {
    color: #000000;
    padding: 30px 30px 0;
}

.legend-item {
    cursor: pointer;
}

.black,
.black p {
    color: #000000;
}

.chart-legends {
    border-top: 2px solid #f3f3f3;
    margin-top: 40px;
}

.bar-chart-legends {
    margin-top: 40px;
}

.bar-legends-list {
    list-style-type: none;
    display: flex;
    justify-content: end;
    align-items: center;
    flex-direction: row;
    column-gap: 25px;
    margin-right: 65px;
}

.legends-list {
    list-style-type: none;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    column-gap: 25px;
    flex-wrap: wrap;
    row-gap: 14px;
    font-size: 12px;
    height: 50px;
}

.legends-list li,
.bar-legends-list li {
    display: flex;
    column-gap: 12px;
    flex-direction: row;
    align-items: center;

}

.legends {
    padding-bottom: 1px;
}

.circle-dot {
    height: 15px;
    width: 15px;
    border-radius: 10px;
}

.graph-container,
.bar-graph-container {
    height: 200px;
    width: 441px;
}

.graph-wrapper {
    display: flex;
    justify-content: center;
}

.bar-chart-wrapper {
    margin-bottom: 30px;
}

.dashboard-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media only screen and (max-width: 500px) {
    .legends-list,
    .bar-legends-list {
        display: table;
        padding: 0;
        justify-content: center;
        margin: 10px auto;
        text-align: center;
    }
    .graph-container {
        height: 200px;
        width: 320px;
    }
    .bar-graph-container {
        height: 200px;
        width: 280px;
    }
}