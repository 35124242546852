.profile-details {
    display: flex;
    justify-content: start;
    align-items: center;
    column-gap: 2%;
    margin-top: 30px;
}

.country-gender-container {
    display: flex;
    column-gap: 20px;
    font-size: 12px;
    color: #af5d68;
}

.profile-full-name {
    display: block;
    justify-content: center;
    font-size: 30px;
    font-weight: 500;
    text-transform: uppercase;
}

.profile-country-name,
.profile-gender {
    background-color: #f9c6bc;
    padding: 0 12px;
    border-radius: 10px;
    color: #e33048;
    font-weight: 600;
}

.back-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 0;
    column-gap: 20px;
}

.padding-zero {
    padding: 0;
}

.secondary-color {
    -webkit-text-fill-color: #EB5D41;
}

.Mui-disabled {
    color: #EB5D41;
}

.message-box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}